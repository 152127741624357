<template>
  <b-row>
    <b-col cols="12"  md="6" xl="4" class="mb-1">
      {{ title }}
    </b-col>
    <b-col cols="12"  md="6" xl="4" class="mb-1">
<b-input-group class="mb-1">
      <b-form-input
        id="example-input"
        v-model="selectedStartDate"
        type="text"
        placeholder="YYYY-MM-DD"
        autocomplete="off"
        show-decade-nav
      />
      <b-input-group-append>
        <b-form-datepicker
          v-model="selectedStartDate"
          show-decade-nav
          button-only
          right
          locale="en-US"
          aria-controls="example-input"
          @context="onContext"
        />
      </b-input-group-append>
    </b-input-group>
    </b-col>
    <b-col cols="12"  md="6" xl="4" class="mb-1">
<b-input-group class="mb-1">
      <b-form-input
        id="example-input"
        v-model="selectedEndDate"
        type="text"
        placeholder="YYYY-MM-DD"
        autocomplete="off"
        show-decade-nav
      />
      <b-input-group-append>
        <b-form-datepicker
          v-model="selectedEndDate"
          show-decade-nav
          button-only
          right
          locale="en-US"
          aria-controls="example-input"
          @context="onContext"
        />
      </b-input-group-append>
    </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormDatepicker, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BFormDatepicker,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  props: {
    title: String,
    startDate: String,
    endDate: String,
  },
  methods: {
    onContext(ctx) {
      this.formatted = ctx.selectedFormatted
      this.selected = ctx.selectedYMD
    },
  },
  computed: {
    selectedStartDate: {
      get() {
        return this.startDate
      },
      set(value) {
        this.$emit('startDtChange', value)
      },
    },
    selectedEndDate: {
      get() {
        return this.endDate
      },
      set(value) {
        this.$emit('endDtChange', value)
      },
    },
  },
}
</script>
