<template>
  <b-card-actions
    ref="campaigns"
    title="List"
    no-actions
    no-body
  >
    <div class="filter-section mx-1 mt-1">
      <b-row class="first-panel">
        <b-col cols="10">
          <HeroInputText
            id="filter"
            v-model="tableConfig.filter"
            placeholder="Search by name..."
            @input="doTableFilter(1000)"
          />
        </b-col>
        <b-col cols="2">
          <HeroTablePerPage
            v-model="tableConfig.perPage"
            :options="tableConfig.perPageOptions"
            @input="doTableFilter(100)"
          />
        </b-col>
      </b-row>
      <div class="second-panel mb-1 mb-xl-0">
        <div class="pl-1">
          <HeroCheckbox
            id="pending-approvals"
            v-model="pendingApprovals"
            class="mb-0"
            label="Pending Approvals"
            @input="doTableFilter(100)"
          />
        </div>
        <div class="pl-1">
          <HeroButtonAction
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-campaign-filter
            type="button"
            variant="outline-primary"
            class="btn-filter pl-1 pr-1"
          >
            <feather-icon
              icon="FilterIcon"
              size="14"
            />
            Filter
          </HeroButtonAction>
          <campaign-filter @filter="getFilter" />
        </div>
        <div class="pl-1">
          <b-dropdown
            id="dropdown-type"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            text="Types"
            right
            block
          >
            <b-dropdown-form style="width: 300px;">
              <HeroCheckbox
                v-for="campaignType in tableConfig.campaignTypes"
                :id="campaignType.key"
                :key="campaignType.key"
                v-model="campaignType.selected"
                :label="campaignType.name"
              />
              <b-dropdown-divider />
              <b-row>
                <b-col cols="6">
                  <HeroButtonAction
                    type="button"
                    variant="primary"
                    @click="typeToggle"
                  >
                    {{ typeToggleText }}
                  </HeroButtonAction>
                </b-col>
                <b-col cols="6">
                  <HeroButtonAction
                    type="button"
                    variant="primary"
                    @click="doLoadData"
                  >
                    Search
                  </HeroButtonAction>
                </b-col>
              </b-row>
            </b-dropdown-form>
          </b-dropdown>
        </div>
        <div
          v-if="hasMyCampaignAccess && hasAllCampaignAccess"
          class="px-1"
        >
          <HeroButtonAction
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-fetch-campaign"
            type="button"
            :variant="campaignFilterOption === 'my-campaign' ? 'primary' : 'outline-primary'"
            @click="myCampaigns()"
          >
            My Campaigns
          </HeroButtonAction>
        </div>
        <div
          v-if="hasMyCampaignAccess || hasAllCampaignAccess"
        >
          <HeroButtonAction
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-fetch-campaign"
            type="button"
            :variant="
              (campaignFilterOption === 'all-campaign' && hasMyCampaignAccess && hasAllCampaignAccess) ||
                (hasMyCampaignAccess && !hasAllCampaignAccess) || (!hasMyCampaignAccess && hasAllCampaignAccess)
                ? 'primary'
                : 'outline-primary'"
            @click="hasMyCampaignAccess && !(hasMyCampaignAccess && hasAllCampaignAccess) ? myCampaigns() : allCampaigns()"
          >
            All Campaigns
          </HeroButtonAction>
        </div>
      </div>
    </div>
    <b-table
      show-empty
      :items="items"
      :fields="tableConfig.fields"
      striped
      responsive
      :sticky-header="true"
      :no-border-collapse="false"
      :sort-by.sync="tableConfig.sortBy"
      :sort-desc.sync="tableConfig.sortDirection"
      :no-sort-reset="true"
      :no-local-sorting="true"
      bordered
      style="font-weight: normal; font-size: 12px;"
      :tbody-tr-class="rowClass"
      @sort-changed="doTableFilter(500)"
    >
      <template #head(paused_date)="data">
        <div :style="`width: ${data.field.thwidth}px;`">
          {{ data.label }}
        </div>
      </template>
      <template #head(unpaused_date)="data">
        <div class="span span:hover" :style="`width: ${data.field.thwidth}px;`">
          {{ data.label }}
        </div>
      </template>
      <template #head(uniqued_selling_point)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #cell(uniqued_selling_point)="data">
        <div class="text-truncate" style="max-width: 200px;" :title="data.item.uniqued_selling_point">
          {{ data.item.uniqued_selling_point }}
        </div>
      </template>
      <template #head(landing_page_type_name)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(landing_page_language_name)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(global_page)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(existing_bot)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(content_from_am)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(required_fields)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(artwork_per_month)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(google_drive_link)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(additional_requirements)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(facebook_page_access)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(line_language_name)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(pieces)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(ana)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(chatbot)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(flow_type)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(lead_data)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(product)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(any_faq)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(any_promotion)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(per_cycle_budget)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(google_account_id)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(client_folder_google_drive)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(additional_information)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(brief_lead_data_requirement)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(brief_product_service_branch)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(brief_faq)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(brief_promotion_offer)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(brief_date)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(actual_campaign_start_date)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(actual_campaign_end_date)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(day_left)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(facebook_account_id)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(tiktok_account_id)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(pricing_model)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(landing_page_type)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(landing_page_language)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(service_focus)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(keyword_ideas)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(line_notifications_email)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(line_notifications_language)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(brief_pieces_sets)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(chatbot_launch_date)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(heroai_campaign_id)="data">
        <div class="span span:hover">
          {{ data.label }}
        </div>
      </template>
      <template #head(campaign_type)="data">
        <div class="span span:hover" :style="{ width: '120px' }">
          {{ data.label }}
        </div>
      </template>
      <template #cell(name)="data">
        <div class="span span:hover" :style="`width: ${data.field.thwidth}px;`">
          <span :class="`text-${dotStatusType(data.item.campaign_status)}`">●</span>
          <router-link :to="{name: 'campaign-detail', params: {id: data.item.id}}">
            {{ data.item.name }}
          </router-link>
        </div>
      </template>
      <template #cell(media_plan)="data">
        <div class="span span:hover">
          {{ data.item.media_plan }}
        </div>
      </template>
      <template #cell(viewers)="data">
        <div class="span span:hover">
          {{ data.item.viewers.join(', ') }}
        </div>
      </template>
      <template #cell(line_notifications_email)="data">
        <div class="span span:hover">
          {{ data.item.line_notifications_email.join(', ') }}
        </div>
      </template>
      <template #cell(google_account_id)="data">
        <div class="span span:hover">
          {{ data.item.google_account_id.join(', ') }}
        </div>
      </template>
      <template #cell(tiktok_account_id)="data">
        <div class="span span:hover">
          {{ data.item.tiktok_account_id.join(', ') }}
        </div>
      </template>
      <template #cell(facebook_account_id)="data">
        <div class="span span:hover">
          {{ data.item.facebook_account_id.join(', ') }}
        </div>
      </template>
      <template #cell(did_phone)="data">
        <div class="span span:hover">
          {{ data.item.did_phone.join(', ') }}
        </div>
      </template>
      <template #cell(keyword_ideas)="data">
        <div class="span span:hover">
          <!-- {{ data.item.keyword_ideas.join(', ') }} -->
          {{ data.item.keyword_ideas }}
        </div>
      </template>
      <template #cell(objective_name)="data">
        <div class="span span:hover">
          {{ data.item.objective_name }}
        </div>
      </template>
      <template #cell(opportunity)="data">
        <div class="span span:hover" style="width: 200px">
          {{ data.item.opportunity }}
        </div>
      </template>
      <template #cell(brief_date)="data">
        {{ formatUsaDate(data.item.brief_date) }}
      </template>
      <template #cell(actual_campaign_start_date)="data">
        {{ formatUsaDate(data.item.actual_campaign_start_date) }}
      </template>
      <template #cell(actual_campaign_end_date)="data">
        {{ formatUsaDate(data.item.actual_campaign_end_date) }}
      </template>
      <template #cell(paused_date)="data">
        {{ formatUsaDate(data.item.paused_date) }}
      </template>
      <template #cell(unpaused_date)="data">
        {{ formatUsaDate(data.item.unpaused_date) }}
      </template>
      <template #cell(chatbot_launch_date)="data">
        {{ formatUsaDate(data.item.chatbot_launch_date) }}
      </template>
      <template #cell(campaign_type)="data">
        <ul class="pl-0">
          <li v-if="data.item.is_creative_campaign">
            Creative Campaign
          </li>
          <li v-if="data.item.is_seo_campaign">
            SEO Campaign
          </li>
        </ul>
      </template>
    </b-table>
    <div class="mx-1 mb-1">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </b-col>
      </b-row>
    </div>
  </b-card-actions>
</template>

<script>
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import SweetAlert from '@/services/SweetAlert'
import AxiosService from '@/services/AxiosService'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import CampaignFactory from '@/factories/Campaign/CampaignFactory'
import Constant from '@/utils/Constant'
import ErrorService from '@/services/ErrorService'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import DataFormatService from '@/services/DataFormatService'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import { BCol, BRow } from 'bootstrap-vue'
import CampaignFilter from './components/Filter.vue'

export default {
  components: {
    BCol,
    BRow,
    CampaignFilter,
    HeroTablePagination,
    HeroTableStatus,
    HeroInputText,
    HeroTablePerPage,
    HeroButtonAction,
    BCardActions,
    HeroCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      demoCampaign: false,
      filters: {},
      items: [],
      pendingApprovals: false,
      campaignFilterOption: localStorage.getItem('campaign-filter') || 'my-campaign',
      tableConfig: {
        campaignTypes: [
          {
            key: 'is_creative_campaign',
            name: 'Creative Campaign',
            selected: false,
          },
          {
            key: 'is_seo_campaign',
            name: 'SEO Campaign',
            selected: false,
          },
        ],
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: false,
        timeInterval: moment(),
        fields: [
          {
            label: 'campaign name',
            key: 'name',
            sortable: true,
            stickyColumn: true,
            thwidth: '200',
          },
          {
            label: 'account name',
            key: 'account_name',
            sortable: false,
          },
          {
            label: 'duration',
            key: 'period',
            sortable: true,
          },
          {
            label: 'status',
            key: 'campaign_status',
            sortable: false,
          },
          {
            label: 'Campaign Type',
            key: 'campaign_type',
            sortable: false,
          },
          {
            label: 'opportunity',
            key: 'opportunity',
            sortable: true,
          },
          {
            label: 'Pricing model',
            key: 'pricing_model',
            sortable: true,
          },
          {
            label: 'brief date',
            key: 'brief_date',
            sortable: true,
            thwidth: '90',
          },
          {
            label: 'start date',
            key: 'actual_campaign_start_date',
            sortable: true,
            thwidth: '90',
          },
          {
            label: 'end date',
            key: 'actual_campaign_end_date',
            sortable: true,
            thwidth: '90',
          },
          {
            label: 'campaign pause date',
            key: 'paused_date',
            sortable: true,
            thwidth: '90',
          },
          {
            label: 'campaign restart date',
            key: 'unpaused_date',
            sortable: true,
            thwidth: '90',
          },
          {
            label: 'day left',
            key: 'day_left',
            sortable: false,
          },
          {
            label: 'kpi',
            key: 'kpi',
            sortable: false,
          },
          {
            label: 'per cycle budget',
            key: 'per_cycle_budget',
            sortable: false,
          },
          {
            label: 'heroAI campaign ID',
            key: 'heroai_campaign_id',
            sortable: false,
          },
          {
            label: 'google account ID',
            key: 'google_account_id',
            sortable: false,
          },
          {
            label: 'facebook account ID',
            key: 'facebook_account_id',
            sortable: false,
          },
          {
            label: 'tiktok account ID',
            key: 'tiktok_account_id',
            sortable: false,
          },
          {
            label: 'bonus/discount',
            key: 'bonus_discount',
            sortable: true,
          },
          {
            label: 'landing page type',
            key: 'landing_page_type',
            sortable: true,
          },
          {
            label: 'landing page language',
            key: 'landing_page_language',
            sortable: true,
          },
          {
            label: 'uniqued selling point',
            key: 'uniqued_selling_point',
            sortable: true,
          },
          {
            label: 'services focus',
            key: 'service_focus',
            sortable: true,
          },
          {
            label: 'keywords ideas',
            key: 'keyword_ideas',
            sortable: true,
          },
          {
            label: 'did number',
            key: 'did_phone',
            sortable: false,
          },
          {
            label: 'creator',
            key: 'creator',
            sortable: true,
          },
          {
            label: 'remark',
            key: 'remark',
            sortable: true,
          },
          {
            label: 'industry',
            key: 'industry',
            sortable: true,
          },
          {
            label: 'media plan',
            key: 'media_plan',
            sortable: true,
          },
          {
            label: 'objectives',
            key: 'objectives',
            sortable: false,
          },
          {
            label: 'country',
            key: 'country_code',
            sortable: false,
          },
          {
            label: 'line notifications',
            key: 'line_notifications_email',
            sortable: false,
          },
          {
            label: 'line notification language',
            key: 'line_notifications_language',
            sortable: false,
          },
          {
            label: 'viewers',
            key: 'viewers',
            sortable: true,
          },
          {
            label: 'How many pieces?',
            key: 'brief_pieces_sets',
            sortable: true,
          },
          {
            label: 'Google Drive Link under client folder',
            key: 'client_folder_google_drive',
            sortable: true,
          },
          {
            label: 'Additional requirements, operation must request Sale at brief meeting',
            key: 'additional_information',
            sortable: true,
          },
          {
            label: 'Facebook page access, please fill page name',
            key: 'facebook_page_access',
            sortable: true,
          },
          {
            label: 'If there is any existing bot and/or Auto-message on the page, can we remove?',
            key: 'existing_bot',
            sortable: true,
          },
          {
            label: 'Content from AM/Client/Copywriter/ for question',
            key: 'content_from_am',
            sortable: true,
          },
          {
            label: 'Chatbot Launch date',
            key: 'chatbot_launch_date',
            sortable: true,
          },
          {
            label: 'Please select Flow type',
            key: 'flow_type',
            sortable: true,
          },
          {
            label: 'Lead data Requirements(email, phone)?',
            key: 'brief_lead_data_requirement',
            sortable: true,
          },
          {
            label: 'Product, service, branch information document',
            key: 'brief_product_service_branch',
            sortable: true,
          },
          {
            label: 'Any FAQ questions to add to the flow?',
            key: 'brief_faq',
            sortable: true,
          },
          {
            label: 'Any promotion offer to show the user?',
            key: 'brief_promotion_offer',
            sortable: true,
          },
        ],
      },
    }
  },
  computed: {
    creatorId() {
      return this.$store.getters['heroAiAuthentications/loginData']?.id
    },

    hasMyCampaignAccess() {
      return this.$can('Access - My Campaign', 'Campaigns')
    },

    hasAllCampaignAccess() {
      return this.$can('Access - All Campaign', 'Campaigns')
    },

    campaignDotStatuses() {
      return Constant.campaignDotStatuses
    },

    typeToggleText() {
      const { campaignTypes } = this.tableConfig
      return campaignTypes.some(campaignType => campaignType.selected) ? 'Clear' : 'All'
    },
  },
  created() {
    this.formatUsaDate = DataFormatService.formatUsaDate
  },
  mounted() {
    if (this.hasMyCampaignAccess && this.hasAllCampaignAccess) {
      if (this.campaignFilterOption === 'my-campaign') {
        this.myCampaigns()
      } else {
        this.allCampaigns()
      }
      return
    }

    if (this.hasMyCampaignAccess) {
      this.myCampaigns()
      return
    }

    if (this.hasAllCampaignAccess) {
      this.allCampaigns()
    }
  },
  methods: {
    async truncateString(label, num) {
      if (label.length <= num) {
        return label
      }
      return label.slice(0, num)
    },

    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.$refs.campaigns.showLoading = true

      try {
        const {
          perPage, currentPage, filter, sortBy, sortDirection, campaignTypes,
        } = this.tableConfig
        const qparam = {
          perPage, page: currentPage, q: filter, orderByColumn: sortBy, orderByDirection: sortDirection ? 'desc' : 'asc',
        }
        if (this.pendingApprovals) {
          qparam.pending_approval = this.pendingApprovals
        }

        campaignTypes.forEach(campaignType => {
          this.filters = {
            ...this.filters,
            [campaignType.key]: campaignType.selected,
          }
        })

        const response = await AxiosService.post(Constant.apiURL.campaign.list, qparam, this.filters)
        if (response.status && response?.data?.data) {
          this.tableConfig.currentPage = Number(response.data.current_page || 1)
          this.tableConfig.perPage = Number(response.data.per_page || 1)
          this.tableConfig.totalRows = Number(response.data.total || 1)
          this.items = CampaignFactory.createFromJsonArray(response.data.data)
        } else {
          this.tableConfig.currentPage = 1
          this.tableConfig.totalRows = 0
          this.items = []
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
        this.items = []
        this.tableConfig.totalRows = 0
      } finally {
        this.$refs.campaigns.showLoading = false
      }
    },

    getFilter(value) {
      this.filters = value
      this.doLoadData()
    },

    async myCampaigns() {
      this.campaignFilterOption = 'my-campaign'
      this.filters.creator_id = this.creatorId
      await this.doLoadData()
    },

    async allCampaigns() {
      this.campaignFilterOption = 'all-campaign'
      this.filters.creator_id = ''
      await this.doLoadData()
    },

    rowClass(item, type) {
      let rowClassValue = ''
      if (!item || type !== 'row') {
        rowClassValue = ''
      } else if (item.is_pending_approval) {
        rowClassValue = 'table-warning'
      }
      return rowClassValue
    },

    dotStatusType(campaignStatusName) {
      const { campaignDotStatuses } = Constant

      const currentDotStatus = campaignDotStatuses.find(campaignDotStatus => campaignDotStatus.name === campaignStatusName)
      return currentDotStatus ? currentDotStatus.type : 'danger'
    },

    typeToggle() {
      if (this.tableConfig.campaignTypes.some(campaignType => campaignType.selected)) {
        this.tableConfig.campaignTypes = this.tableConfig.campaignTypes.map(campaignType => ({
          ...campaignType,
          selected: false,
        }))
      } else {
        this.tableConfig.campaignTypes = this.tableConfig.campaignTypes.map(campaignType => ({
          ...campaignType,
          selected: true,
        }))
      }
    },
  },
}
</script>

<style>
.span {
  width: 100px; /* can be 100% ellipsis will happen when contents exceed it */
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.span:hover {
  white-space: normal;
  /* or:
  width: auto
  */
}
.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}
.b-table-sticky-header {
  max-height: calc(100vh - 350px);
}
tbody .b-table-sticky-column {
  background-image: none !important;
  background-color: transparent !important;
}
.table-warning .b-table-sticky-column {
  background-image: none !important;
  background-color: #ffe4ca !important;
}
.dark-layout .table-warning td {
  color: black !important;
}

.filter-section {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media(min-width: 1200px) {
    flex-direction: row;
  }
  .first-panel {
    align-items: center;
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
    @media(min-width: 1200px) {
      max-width: 40%;
      flex: 0 0 40%;
    }
  }
  .second-panel {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 1rem;
    @media(min-width: 1200px) {
      flex-wrap: nowrap;
      row-gap: 0;
    }
    .btn-fetch-campaign {
      width: auto;
      @media(min-width: 1200px) {
        width: 150px;
      }
    }
    .btn-filter {
      width: auto;
      @media(min-width: 1200px) {
        width: 90px;
      }
    }
  }
}
</style>
