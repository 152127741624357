<template>
  <b-row>
    <b-col cols="12" md="6" xl="4" class="mb-1">
      {{ title }}
    </b-col>
    <b-col cols="12" md="6" xl="4" class="mb-1">
      <b-form-group :label="label1">
        <v-select
          v-model="selectedValue"
          :label="field1title"
          :options="option"
        />
      </b-form-group>
    </b-col>
    <b-col v-if="selectedValue && selectedValue.value !== 'between'" cols="12" md="6" xl="4" class="mb-1">
      <b-form-group :label="label2">
        <b-form-input v-model="inputValue" />
      </b-form-group>
    </b-col>
    <b-col v-if="selectedValue && selectedValue.value === 'between'" cols="12" md="3" xl="2" class="mb-1">
      <b-form-group :label="label3">
        <b-form-input v-model="inputValue1" />
      </b-form-group>
    </b-col>
    <b-col v-if="selectedValue && selectedValue.value === 'between'" cols="12" md="3" xl="2" class="mb-1">
      <b-form-group :label="label4">
        <b-form-input v-model="inputValue2" />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BCol,
    BRow,
    BFormInput,
    vSelect,
  },
  props: {
    title: { type: String, default: '' },
    label1: { type: String, default: '' },
    selected: { type: Object, default: () => {} },
    field1title: { type: String, default: '' },
    option: { type: Array, default: new Array([]) },
    label2: { type: String, default: '' },
    inputText: { type: String, default: '' },
    label3: { type: String, default: '' },
    inputText1: { type: String, default: '' },
    label4: { type: String, default: '' },
    inputText2: { type: String, default: '' },
  },
  computed: {
    selectedValue: {
      get() {
        return this.selected
      },
      set(value) {
        this.$emit('listchange', value)
      },
    },
    inputValue: {
      get() {
        return this.inputText
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    inputValue1: {
      get() {
        return this.inputText1
      },
      set(value) {
        this.$emit('input1', value)
      },
    },
    inputValue2: {
      get() {
        return this.inputText2
      },
      set(value) {
        this.$emit('input2', value)
      },
    },
  },
}
</script>
