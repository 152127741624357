<template>
  <b-modal
    id="modal-campaign-filter"
    scrollable
    title="Filter"
    cancel-title="Cancel"
    ok-title="Save"
    cancel-variant="outline-secondary"
    size="lg"
    @ok="save"
  >
    <b-form @submit.stop.prevent>
      <TextFieldFilter
        :title="filterModel.name.title"
        :label1="filterModel.name.label1"
        :field1title="filterModel.name.field1title"
        :option="filterModel.name.option"
        :label2="filterModel.name.label2"
        :selected="vmodel.name.operator"
        :input-text="vmodel.name.value"
        @listchange="vmodel.name.operator = $event"
        @input="vmodel.name.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.account_number.title"
        :label1="filterModel.account_number.label1"
        :field1title="filterModel.account_number.field1title"
        :option="filterModel.account_number.option"
        :label2="filterModel.account_number.label2"
        :selected="vmodel.account_number.operator"
        :input-text="vmodel.account_number.value"
        @listchange="vmodel.account_number.operator = $event"
        @input="vmodel.account_number.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.campaign_id.title"
        :label1="filterModel.campaign_id.label1"
        :field1title="filterModel.campaign_id.field1title"
        :option="filterModel.campaign_id.option"
        :label2="filterModel.campaign_id.label2"
        :selected="vmodel.campaign_id.operator"
        :input-text="vmodel.campaign_id.value"
        @listchange="vmodel.campaign_id.operator = $event"
        @input="vmodel.campaign_id.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.campaign_code.title"
        :label1="filterModel.campaign_code.label1"
        :field1title="filterModel.campaign_code.field1title"
        :option="filterModel.campaign_code.option"
        :label2="filterModel.campaign_code.label2"
        :selected="vmodel.campaign_code.operator"
        :input-text="vmodel.campaign_code.value"
        @listchange="vmodel.campaign_code.operator = $event"
        @input="vmodel.campaign_code.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.duration.title"
        :label1="filterModel.duration.label1"
        :field1title="filterModel.duration.field1title"
        :option="filterModel.duration.option"
        :label2="filterModel.duration.label2"
        :selected="vmodel.duration.operator"
        :input-text="vmodel.duration.value"
        @listchange="vmodel.duration.operator = $event"
        @input="vmodel.duration.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.status.title"
        :label1="filterModel.status.label1"
        :field1title="filterModel.status.field1title"
        :option="filterModel.status.option"
        :label2="filterModel.status.label2"
        :selected="vmodel.status.operator"
        :input-text="vmodel.status.value"
        @listchange="vmodel.status.operator = $event"
        @input="vmodel.status.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.opportunity_name.title"
        :label1="filterModel.opportunity_name.label1"
        :field1title="filterModel.opportunity_name.field1title"
        :option="filterModel.opportunity_name.option"
        :label2="filterModel.opportunity_name.label2"
        :selected="vmodel.opportunity_name.operator"
        :input-text="vmodel.opportunity_name.value"
        @listchange="vmodel.opportunity_name.operator = $event"
        @input="vmodel.opportunity_name.value = $event"
      />
      <DateFilter
        :title="filterModel.brief_date.title"
        :start-date="vmodel.brief_date.startDate"
        :end-date="vmodel.brief_date.endDate"
        @startDtChange="vmodel.brief_date.startDate = $event"
        @endDtChange="vmodel.brief_date.endDate = $event"
      />
      <DateFilter
        :title="filterModel.start_date.title"
        :start-date="vmodel.start_date.startDate"
        :end-date="vmodel.start_date.endDate"
        @startDtChange="vmodel.start_date.startDate = $event"
        @endDtChange="vmodel.start_date.endDate = $event"
      />
      <DateFilter
        :title="filterModel.end_date.title"
        :start-date="vmodel.end_date.startDate"
        :end-date="vmodel.end_date.endDate"
        @startDtChange="vmodel.end_date.startDate = $event"
        @endDtChange="vmodel.end_date.endDate = $event"
      />
      <DateFilter
        :title="filterModel.paused_date.title"
        :start-date="vmodel.paused_date.startDate"
        :end-date="vmodel.paused_date.endDate"
        @startDtChange="vmodel.paused_date.startDate = $event"
        @endDtChange="vmodel.paused_date.endDate = $event"
      />
      <DateFilter
        :title="filterModel.restart_date.title"
        :start-date="vmodel.restart_date.startDate"
        :end-date="vmodel.restart_date.endDate"
        @startDtChange="vmodel.restart_date.startDate = $event"
        @endDtChange="vmodel.restart_date.endDate = $event"
      />
      <TextFieldFilter
        :title="filterModel.kpi.title"
        :label1="filterModel.kpi.label1"
        :field1title="filterModel.kpi.field1title"
        :option="filterModel.kpi.option"
        :label2="filterModel.kpi.label2"
        :selected="vmodel.kpi.operator"
        :input-text="vmodel.kpi.value"
        @listchange="vmodel.kpi.operator = $event"
        @input="vmodel.kpi.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.per_cycle_budget.title"
        :label1="filterModel.per_cycle_budget.label1"
        :field1title="filterModel.per_cycle_budget.field1title"
        :option="filterModel.per_cycle_budget.option"
        :label2="filterModel.per_cycle_budget.label2"
        :selected="vmodel.per_cycle_budget.operator"
        :input-text="vmodel.per_cycle_budget.value"
        @listchange="vmodel.per_cycle_budget.operator = $event"
        @input="vmodel.per_cycle_budget.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.heroai_campaign_id.title"
        :label1="filterModel.heroai_campaign_id.label1"
        :field1title="filterModel.heroai_campaign_id.field1title"
        :option="filterModel.heroai_campaign_id.option"
        :label2="filterModel.heroai_campaign_id.label2"
        :selected="vmodel.heroai_campaign_id.operator"
        :input-text="vmodel.heroai_campaign_id.value"
        @listchange="vmodel.heroai_campaign_id.operator = $event"
        @input="vmodel.heroai_campaign_id.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.google_account_id.title"
        :label1="filterModel.google_account_id.label1"
        :field1title="filterModel.google_account_id.field1title"
        :option="filterModel.google_account_id.option"
        :label2="filterModel.google_account_id.label2"
        :selected="vmodel.google_account_id.operator"
        :input-text="vmodel.google_account_id.value"
        @listchange="vmodel.google_account_id.operator = $event"
        @input="vmodel.google_account_id.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.facebook_account_id.title"
        :label1="filterModel.facebook_account_id.label1"
        :field1title="filterModel.facebook_account_id.field1title"
        :option="filterModel.facebook_account_id.option"
        :label2="filterModel.facebook_account_id.label2"
        :selected="vmodel.facebook_account_id.operator"
        :input-text="vmodel.facebook_account_id.value"
        @listchange="vmodel.facebook_account_id.operator = $event"
        @input="vmodel.facebook_account_id.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.tiktok_account_id.title"
        :label1="filterModel.tiktok_account_id.label1"
        :field1title="filterModel.tiktok_account_id.field1title"
        :option="filterModel.tiktok_account_id.option"
        :label2="filterModel.tiktok_account_id.label2"
        :selected="vmodel.tiktok_account_id.operator"
        :input-text="vmodel.tiktok_account_id.value"
        @listchange="vmodel.tiktok_account_id.operator = $event"
        @input="vmodel.tiktok_account_id.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.pricing_model.title"
        :label1="filterModel.pricing_model.label1"
        :field1title="filterModel.pricing_model.field1title"
        :option="filterModel.pricing_model.option"
        :label2="filterModel.pricing_model.label2"
        :selected="vmodel.pricing_model.operator"
        :input-text="vmodel.pricing_model.value"
        @listchange="vmodel.pricing_model.operator = $event"
        @input="vmodel.pricing_model.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.bonus_discount.title"
        :label1="filterModel.bonus_discount.label1"
        :field1title="filterModel.bonus_discount.field1title"
        :option="filterModel.bonus_discount.option"
        :label2="filterModel.bonus_discount.label2"
        :selected="vmodel.bonus_discount.operator"
        :input-text="vmodel.bonus_discount.value"
        @listchange="vmodel.bonus_discount.operator = $event"
        @input="vmodel.bonus_discount.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.landing_page_type.title"
        :label1="filterModel.landing_page_type.label1"
        :field1title="filterModel.landing_page_type.field1title"
        :option="filterModel.landing_page_type.option"
        :label2="filterModel.landing_page_type.label2"
        :selected="vmodel.landing_page_type.operator"
        :input-text="vmodel.landing_page_type.value"
        @listchange="vmodel.landing_page_type.operator = $event"
        @input="vmodel.landing_page_type.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.uniqued_selling_point.title"
        :label1="filterModel.uniqued_selling_point.label1"
        :field1title="filterModel.uniqued_selling_point.field1title"
        :option="filterModel.uniqued_selling_point.option"
        :label2="filterModel.uniqued_selling_point.label2"
        :selected="vmodel.uniqued_selling_point.operator"
        :input-text="vmodel.uniqued_selling_point.value"
        @listchange="vmodel.uniqued_selling_point.operator = $event"
        @input="vmodel.uniqued_selling_point.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.service_focus.title"
        :label1="filterModel.service_focus.label1"
        :field1title="filterModel.service_focus.field1title"
        :option="filterModel.service_focus.option"
        :label2="filterModel.service_focus.label2"
        :selected="vmodel.service_focus.operator"
        :input-text="vmodel.service_focus.value"
        @listchange="vmodel.service_focus.operator = $event"
        @input="vmodel.service_focus.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.keyword_ideas.title"
        :label1="filterModel.keyword_ideas.label1"
        :field1title="filterModel.keyword_ideas.field1title"
        :option="filterModel.keyword_ideas.option"
        :label2="filterModel.keyword_ideas.label2"
        :selected="vmodel.keyword_ideas.operator"
        :input-text="vmodel.keyword_ideas.value"
        @listchange="vmodel.keyword_ideas.operator = $event"
        @input="vmodel.keyword_ideas.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.did_phone.title"
        :label1="filterModel.did_phone.label1"
        :field1title="filterModel.did_phone.field1title"
        :option="filterModel.did_phone.option"
        :label2="filterModel.did_phone.label2"
        :selected="vmodel.did_phone.operator"
        :input-text="vmodel.did_phone.value"
        @listchange="vmodel.did_phone.operator = $event"
        @input="vmodel.did_phone.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.remark.title"
        :label1="filterModel.remark.label1"
        :field1title="filterModel.remark.field1title"
        :option="filterModel.remark.option"
        :label2="filterModel.remark.label2"
        :selected="vmodel.remark.operator"
        :input-text="vmodel.remark.value"
        @listchange="vmodel.remark.operator = $event"
        @input="vmodel.remark.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.industry.title"
        :label1="filterModel.industry.label1"
        :field1title="filterModel.industry.field1title"
        :option="filterModel.industry.option"
        :label2="filterModel.industry.label2"
        :selected="vmodel.industry.operator"
        :input-text="vmodel.industry.value"
        @listchange="vmodel.industry.operator = $event"
        @input="vmodel.industry.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.media_plan.title"
        :label1="filterModel.media_plan.label1"
        :field1title="filterModel.media_plan.field1title"
        :option="filterModel.media_plan.option"
        :label2="filterModel.media_plan.label2"
        :selected="vmodel.media_plan.operator"
        :input-text="vmodel.media_plan.value"
        @listchange="vmodel.media_plan.operator = $event"
        @input="vmodel.media_plan.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.objectives.title"
        :label1="filterModel.objectives.label1"
        :field1title="filterModel.objectives.field1title"
        :option="filterModel.objectives.option"
        :label2="filterModel.objectives.label2"
        :selected="vmodel.objectives.operator"
        :input-text="vmodel.objectives.value"
        @listchange="vmodel.objectives.operator = $event"
        @input="vmodel.objectives.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.country.title"
        :label1="filterModel.country.label1"
        :field1title="filterModel.country.field1title"
        :option="filterModel.country.option"
        :label2="filterModel.country.label2"
        :selected="vmodel.country.operator"
        :input-text="vmodel.country.value"
        @listchange="vmodel.country.operator = $event"
        @input="vmodel.country.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.line_notifications.title"
        :label1="filterModel.line_notifications.label1"
        :field1title="filterModel.line_notifications.field1title"
        :option="filterModel.line_notifications.option"
        :label2="filterModel.line_notifications.label2"
        :selected="vmodel.line_notifications.operator"
        :input-text="vmodel.line_notifications.value"
        @listchange="vmodel.line_notifications.operator = $event"
        @input="vmodel.line_notifications.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.line_notifications_language.title"
        :label1="filterModel.line_notifications_language.label1"
        :field1title="filterModel.line_notifications_language.field1title"
        :option="filterModel.line_notifications_language.option"
        :label2="filterModel.line_notifications_language.label2"
        :selected="vmodel.line_notifications_language.operator"
        :input-text="vmodel.line_notifications_language.value"
        @listchange="vmodel.line_notifications_language.operator = $event"
        @input="vmodel.line_notifications_language.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.brief_commit_artwork.title"
        :label1="filterModel.brief_commit_artwork.label1"
        :field1title="filterModel.brief_commit_artwork.field1title"
        :option="filterModel.brief_commit_artwork.option"
        :label2="filterModel.brief_commit_artwork.label2"
        :selected="vmodel.brief_commit_artwork.operator"
        :input-text="vmodel.brief_commit_artwork.value"
        @listchange="vmodel.brief_commit_artwork.operator = $event"
        @input="vmodel.brief_commit_artwork.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.brief_pieces_sets.title"
        :label1="filterModel.brief_pieces_sets.label1"
        :field1title="filterModel.brief_pieces_sets.field1title"
        :option="filterModel.brief_pieces_sets.option"
        :label2="filterModel.brief_pieces_sets.label2"
        :selected="vmodel.brief_pieces_sets.operator"
        :input-text="vmodel.brief_pieces_sets.value"
        @listchange="vmodel.brief_pieces_sets.operator = $event"
        @input="vmodel.brief_pieces_sets.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.client_folder_google_drive.title"
        :label1="filterModel.client_folder_google_drive.label1"
        :field1title="filterModel.client_folder_google_drive.field1title"
        :option="filterModel.client_folder_google_drive.option"
        :label2="filterModel.client_folder_google_drive.label2"
        :selected="vmodel.client_folder_google_drive.operator"
        :input-text="vmodel.client_folder_google_drive.value"
        @listchange="vmodel.client_folder_google_drive.operator = $event"
        @input="vmodel.client_folder_google_drive.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.facebook_page_access.title"
        :label1="filterModel.facebook_page_access.label1"
        :field1title="filterModel.facebook_page_access.field1title"
        :option="filterModel.facebook_page_access.option"
        :label2="filterModel.facebook_page_access.label2"
        :selected="vmodel.facebook_page_access.operator"
        :input-text="vmodel.facebook_page_access.value"
        @listchange="vmodel.facebook_page_access.operator = $event"
        @input="vmodel.facebook_page_access.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.auto_message.title"
        :label1="filterModel.auto_message.label1"
        :field1title="filterModel.auto_message.field1title"
        :option="filterModel.auto_message.option"
        :label2="filterModel.auto_message.label2"
        :selected="vmodel.auto_message.operator"
        :input-text="vmodel.auto_message.value"
        @listchange="vmodel.auto_message.operator = $event"
        @input="vmodel.auto_message.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.content_from_am.title"
        :label1="filterModel.content_from_am.label1"
        :field1title="filterModel.content_from_am.field1title"
        :option="filterModel.content_from_am.option"
        :label2="filterModel.content_from_am.label2"
        :selected="vmodel.content_from_am.operator"
        :input-text="vmodel.content_from_am.value"
        @listchange="vmodel.content_from_am.operator = $event"
        @input="vmodel.content_from_am.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.fields_chat_lead.title"
        :label1="filterModel.fields_chat_lead.label1"
        :field1title="filterModel.fields_chat_lead.field1title"
        :option="filterModel.fields_chat_lead.option"
        :label2="filterModel.fields_chat_lead.label2"
        :selected="vmodel.fields_chat_lead.operator"
        :input-text="vmodel.fields_chat_lead.value"
        @listchange="vmodel.fields_chat_lead.operator = $event"
        @input="vmodel.fields_chat_lead.value = $event"
      />
      <DateFilter
        :title="filterModel.chatbot_launch_date.title"
        :start-date="vmodel.chatbot_launch_date.from"
        :end-date="vmodel.chatbot_launch_date.to"
        @startDtChange="vmodel.chatbot_launch_date.from = $event"
        @endDtChange="vmodel.chatbot_launch_date.to = $event"
      />
      <TextFieldFilter
        :title="filterModel.flow_type.title"
        :label1="filterModel.flow_type.label1"
        :field1title="filterModel.flow_type.field1title"
        :option="filterModel.flow_type.option"
        :label2="filterModel.flow_type.label2"
        :selected="vmodel.flow_type.operator"
        :input-text="vmodel.flow_type.value"
        @listchange="vmodel.flow_type.operator = $event"
        @input="vmodel.flow_type.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.brief_lead_data_requirement.title"
        :label1="filterModel.brief_lead_data_requirement.label1"
        :field1title="filterModel.brief_lead_data_requirement.field1title"
        :option="filterModel.brief_lead_data_requirement.option"
        :label2="filterModel.brief_lead_data_requirement.label2"
        :selected="vmodel.brief_lead_data_requirement.operator"
        :input-text="vmodel.brief_lead_data_requirement.value"
        @listchange="vmodel.brief_lead_data_requirement.operator = $event"
        @input="vmodel.brief_lead_data_requirement.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.brief_product_service_branch.title"
        :label1="filterModel.brief_product_service_branch.label1"
        :field1title="filterModel.brief_product_service_branch.field1title"
        :option="filterModel.brief_product_service_branch.option"
        :label2="filterModel.brief_product_service_branch.label2"
        :selected="vmodel.brief_product_service_branch.operator"
        :input-text="vmodel.brief_product_service_branch.value"
        @listchange="vmodel.brief_product_service_branch.operator = $event"
        @input="vmodel.brief_product_service_branch.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.brief_faq.title"
        :label1="filterModel.brief_faq.label1"
        :field1title="filterModel.brief_faq.field1title"
        :option="filterModel.brief_faq.option"
        :label2="filterModel.brief_faq.label2"
        :selected="vmodel.brief_faq.operator"
        :input-text="vmodel.brief_faq.value"
        @listchange="vmodel.brief_faq.operator = $event"
        @input="vmodel.brief_faq.value = $event"
      />
      <TextFieldFilter
        :title="filterModel.brief_promotion_offer.title"
        :label1="filterModel.brief_promotion_offer.label1"
        :field1title="filterModel.brief_promotion_offer.field1title"
        :option="filterModel.brief_promotion_offer.option"
        :label2="filterModel.brief_promotion_offer.label2"
        :selected="vmodel.brief_promotion_offer.operator"
        :input-text="vmodel.brief_promotion_offer.value"
        @listchange="vmodel.brief_promotion_offer.operator = $event"
        @input="vmodel.brief_promotion_offer.value = $event"
      />
    </b-form>
  </b-modal>
</template>
<script>
import TextFieldFilter from '@/views/heroai/opportunities/view/components/TextFieldFilter.vue'
import DateFilter from '@/views/heroai/opportunities/view/components/DateFilter.vue'

export default {
  components: {
    TextFieldFilter,
    DateFilter,
  },
  data() {
    return {
      filterModel: {
        name: {
          title: 'Campaign Name',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        account_number: {
          title: 'Account Number',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        campaign_id: {
          title: 'Campaign ID',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        campaign_code: {
          title: 'Code',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        duration: {
          title: 'Duration',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        status: {
          title: 'Status',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        opportunity_name: {
          title: 'Opportunity Name',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        brief_date: {
          title: 'Brief Date',
        },
        start_date: {
          title: 'Start Date',
        },
        end_date: {
          title: 'End Date',
        },
        paused_date: {
          title: 'Campaign Pause Date',
        },
        restart_date: {
          title: 'Campaign Restart Date',
        },
        kpi: {
          title: 'KPI',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        per_cycle_budget: {
          title: 'Per Cycle Budget',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        heroai_campaign_id: {
          title: 'HeroAI Campaign ID',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        google_account_id: {
          title: 'Google Account ID',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        facebook_account_id: {
          title: 'Facebook Account ID',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        tiktok_account_id: {
          title: 'TikTok Account ID',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        pricing_model: {
          title: 'Pricing Model',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        bonus_discount: {
          title: 'Bonus/Discount',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        landing_page_type: {
          title: 'Landing Page Type',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        uniqued_selling_point: {
          title: 'Uniqued Selling Point',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        service_focus: {
          title: 'Services Focus',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        keyword_ideas: {
          title: 'Keywords Ideas',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        did_phone: {
          title: 'DID Phone',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        remark: {
          title: 'Remark',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        industry: {
          title: 'Industry',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        media_plan: {
          title: 'Media Plan',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        objectives: {
          title: 'Objectives',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        country: {
          title: 'Country',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        line_notifications: {
          title: 'Line Notifications',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        line_notifications_language: {
          title: 'Line Notification Language',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        brief_commit_artwork: {
          title: 'Viewers Do you commit amount of Artwork per month?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        brief_pieces_sets: {
          title: 'How many pieces?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        client_folder_google_drive: {
          title: 'Google Drive Link under client folder',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        facebook_page_access: {
          title:
            'Additional requirements, operation must request Sale at brief meeting Facebook page access, please fill page name',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        auto_message: {
          title:
            'If there is any existing bot and/or Auto-message on the page, can we remove?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        content_from_am: {
          title: 'Content from AM/Client/Copywriter/ for question',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        fields_chat_lead: {
          title: 'Required fields from chat lead (Email, surname, etc.)',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        chatbot_launch_date: {
          title: 'Chatbot Launch date',
        },
        flow_type: {
          title: 'Please select Flow type',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        brief_lead_data_requirement: {
          title: 'Lead data Requirements(email, phone)?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        brief_product_service_branch: {
          title: 'Product, service, branch information document',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        brief_faq: {
          title: 'Any FAQ questions to add to the flow?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        brief_promotion_offer: {
          title: 'Any promotion offer to show the user?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
      },
      vmodel: {
        name: {
          operator: '',
          value: '',
        },
        account_number: {
          operator: '',
          value: '',
        },
        campaign_id: {
          operator: '',
          value: '',
        },
        campaign_code: {
          operator: '',
          value: '',
        },
        duration: {
          operator: '',
          value: '',
        },
        status: {
          operator: '',
          value: '',
        },
        opportunity_name: {
          operator: '',
          value: '',
        },
        brief_date: {
          startDate: '',
          endDate: '',
        },
        start_date: {
          startDate: '',
          endDate: '',
        },
        end_date: {
          startDate: '',
          endDate: '',
        },
        paused_date: {
          startDate: '',
          endDate: '',
        },
        restart_date: {
          startDate: '',
          endDate: '',
        },
        kpi: {
          operator: '',
          value: '',
        },
        per_cycle_budget: {
          operator: '',
          value: '',
        },
        heroai_campaign_id: {
          operator: '',
          value: '',
        },
        google_account_id: {
          operator: '',
          value: '',
        },
        facebook_account_id: {
          operator: '',
          value: '',
        },
        tiktok_account_id: {
          operator: '',
          value: '',
        },
        pricing_model: {
          operator: '',
          value: '',
        },
        bonus_discount: {
          operator: '',
          value: '',
        },
        landing_page_type: {
          operator: '',
          value: '',
        },
        uniqued_selling_point: {
          operator: '',
          value: '',
        },
        service_focus: {
          operator: '',
          value: '',
        },
        keyword_ideas: {
          operator: '',
          value: '',
        },
        did_phone: {
          operator: '',
          value: '',
        },
        remark: {
          operator: '',
          value: '',
        },
        industry: {
          operator: '',
          value: '',
        },
        media_plan: {
          operator: '',
          value: '',
        },
        objectives: {
          operator: '',
          value: '',
        },
        country: {
          operator: '',
          value: '',
        },
        line_notifications: {
          operator: '',
          value: '',
        },
        line_notifications_language: {
          operator: '',
          value: '',
        },
        brief_commit_artwork: {
          operator: '',
          value: '',
        },
        brief_pieces_sets: {
          operator: '',
          value: '',
        },
        client_folder_google_drive: {
          operator: '',
          value: '',
        },
        facebook_page_access: {
          operator: '',
          value: '',
        },
        auto_message: {
          operator: '',
          value: '',
        },
        content_from_am: {
          operator: '',
          value: '',
        },
        fields_chat_lead: {
          operator: '',
          value: '',
        },
        chatbot_launch_date: {
          from: '',
          to: '',
        },
        flow_type: {
          operator: '',
          value: '',
        },
        brief_lead_data_requirement: {
          operator: '',
          value: '',
        },
        brief_product_service_branch: {
          operator: '',
          value: '',
        },
        brief_faq: {
          operator: '',
          value: '',
        },
        brief_promotion_offer: {
          operator: '',
          value: '',
        },
      },
      selected: 'Contain',
      selected2: 'Greater Than',
      option: ['Contain', 'Equal'],
      option2: [
        'Greater Than',
        'Equal',
        'Less Than',
        'Between',
      ],
    }
  },
  async mounted() {
    this.vmodel.name.selected = this.selected.title
    this.filterModel.name.option = this.option
    this.vmodel.account_number.selected = this.selected.title
    this.filterModel.account_number.option = this.option
    this.vmodel.campaign_id.selected = this.selected.title
    this.filterModel.campaign_id.option = this.option
    this.vmodel.campaign_code.selected = this.selected.title
    this.filterModel.campaign_code.option = this.option
    this.vmodel.duration.selected = this.selected.title
    this.filterModel.duration.option = this.option
    this.vmodel.status.selected = this.selected.title
    this.filterModel.status.option = this.option
    this.vmodel.opportunity_name.selected = this.selected.title
    this.filterModel.opportunity_name.option = this.option
    this.vmodel.brief_date.startDate = this.startDate
    this.vmodel.brief_date.endDate = this.endDate
    this.vmodel.start_date.startDate = this.startDate
    this.vmodel.start_date.endDate = this.endDate
    this.vmodel.end_date.startDate = this.startDate
    this.vmodel.end_date.endDate = this.endDate
    this.vmodel.paused_date.startDate = this.startDate
    this.vmodel.paused_date.endDate = this.endDate
    this.vmodel.restart_date.startDate = this.startDate
    this.vmodel.restart_date.endDate = this.endDate
    this.vmodel.kpi.selected = this.selected.title
    this.filterModel.kpi.option = this.option
    this.vmodel.per_cycle_budget.selected = this.selected.title2
    this.filterModel.per_cycle_budget.option = this.option2
    this.vmodel.heroai_campaign_id.selected = this.selected.title
    this.filterModel.heroai_campaign_id.option = this.option
    this.vmodel.google_account_id.selected = this.selected.title
    this.filterModel.google_account_id.option = this.option
    this.vmodel.facebook_account_id.selected = this.selected.title
    this.filterModel.facebook_account_id.option = this.option
    this.vmodel.tiktok_account_id.selected = this.selected.title
    this.filterModel.tiktok_account_id.option = this.option
    this.vmodel.pricing_model.selected = this.selected.title
    this.filterModel.pricing_model.option = this.option
    this.vmodel.bonus_discount.selected = this.selected.title
    this.filterModel.bonus_discount.option = this.option
    this.vmodel.landing_page_type.selected = this.selected.title
    this.filterModel.landing_page_type.option = this.option
    this.vmodel.uniqued_selling_point.selected = this.selected.title
    this.filterModel.uniqued_selling_point.option = this.option
    this.vmodel.service_focus.selected = this.selected.title
    this.filterModel.service_focus.option = this.option
    this.vmodel.keyword_ideas.selected = this.selected.title
    this.filterModel.keyword_ideas.option = this.option
    this.vmodel.did_phone.selected = this.selected.title
    this.filterModel.did_phone.option = this.option
    this.vmodel.remark.selected = this.selected.title
    this.filterModel.remark.option = this.option
    this.vmodel.industry.selected = this.selected.title
    this.filterModel.industry.option = this.option
    this.vmodel.media_plan.selected = this.selected.title
    this.filterModel.media_plan.option = this.option
    this.vmodel.objectives.selected = this.selected.title
    this.filterModel.objectives.option = this.option
    this.vmodel.country.selected = this.selected.title
    this.filterModel.country.option = this.option
    this.vmodel.line_notifications.selected = this.selected.title
    this.filterModel.line_notifications.option = this.option
    this.vmodel.line_notifications_language.selected = this.selected.title
    this.filterModel.line_notifications_language.option = this.option
    this.vmodel.brief_commit_artwork.selected = this.selected.title
    this.filterModel.brief_commit_artwork.option = this.option
    this.vmodel.brief_pieces_sets.selected = this.selected.title
    this.filterModel.brief_pieces_sets.option = this.option
    this.vmodel.client_folder_google_drive.selected = this.selected.title
    this.filterModel.client_folder_google_drive.option = this.option
    this.vmodel.facebook_page_access.selected = this.selected.title
    this.filterModel.facebook_page_access.option = this.option
    this.vmodel.auto_message.selected = this.selected.title
    this.filterModel.auto_message.option = this.option
    this.vmodel.content_from_am.selected = this.selected.title
    this.filterModel.content_from_am.option = this.option
    this.vmodel.fields_chat_lead.selected = this.selected.title
    this.filterModel.fields_chat_lead.option = this.option
    this.vmodel.chatbot_launch_date.from = this.startDate
    this.vmodel.chatbot_launch_date.to = this.endDate
    this.vmodel.flow_type.selected = this.selected.title
    this.filterModel.flow_type.option = this.option
    this.vmodel.brief_lead_data_requirement.selected = this.selected.title
    this.filterModel.brief_lead_data_requirement.option = this.option
    this.vmodel.brief_product_service_branch.selected = this.selected.title
    this.filterModel.brief_product_service_branch.option = this.option
    this.vmodel.brief_faq.selected = this.selected.title
    this.filterModel.brief_faq.option = this.option
    this.vmodel.brief_promotion_offer.selected = this.selected.title
    this.filterModel.brief_promotion_offer.option = this.option
  },
  methods: {
    save() {
      this.$emit('filter', this.vmodel)
    },
  },
}
</script>
